import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget10,
  MixedWidget8,
  MixedWidget5,
  MixedWidget3,
} from '../../../_metronic/partials/widgets'
import {
  
  Link
} from "react-router-dom";
import axios from "axios";
import { Spinner } from 'react-bootstrap'
let dataFetched = false;
const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
    </>
  )
}
let dashboardInitialData;


const DashboardWrapper = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const initialSpinner = <Spinner animation='border' />
  const [dashboaradData,setDashboaradData] = useState(dashboardInitialData);
  function getDashboardPageData(curUser) {
      dashboardInitialData = {
        total_emis:initialSpinner,
        paid_emis: initialSpinner,
        unpaid_emis: initialSpinner,
        pending_emis: initialSpinner,
        upcoming_emis: initialSpinner,
        today_paid_amount: initialSpinner,
        financer_invested_amount: initialSpinner,
        financer_returnable_amount: initialSpinner,
        financer_recovered_amount: initialSpinner,
        financer_balance_amount: initialSpinner,
        financcer_profit: initialSpinner,
        sponsor_profit: initialSpinner
      }
      setDashboaradData(dashboardInitialData)
      dataFetched=true;
      axios({
       url:'https://api.lastflightmarketing.com/api/admin/getdashboarddata',
       method:'GET',
        headers: {
          'Authorization': 'Bearer '+curUser?.api_token,
          'Content-Type': 'application/json'
      }
      })
          .then((response) => response.data).then((response) => {
            setDashboaradData(response.data);
      })
  
  }
    useEffect(() => {
  getDashboardPageData(currentUser);
      },[])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {/*<DashboardPage />*/}

      <div className={'row'}>
        <div className={'col'}>
          <h3>EMI Details</h3>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Total EMIs
              </div>
              <div className={'col text-end text-white'}>
                {  dashboaradData?.total_emis}
                
              </div>
            </div>
          </div>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Paid EMIs
              </div>
              <div className={'col text-end text-white'}>
                {dashboaradData?.paid_emis}
              </div>
            </div>
          </div>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Unpaid EMIs
              </div>
              <div className={'col text-end text-white'}>
                {dashboaradData?.unpaid_emis}
              </div>
            </div>
          </div>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Pending EMIs
              </div>
              <div className={'col text-end text-white'}>
                {dashboaradData?.pending_emis}
              </div>
            </div>
          </div>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Upcoming EMIs
              </div>
              <div className={'col text-end text-white'}>
                {dashboaradData?.upcoming_emis}
              </div>
            </div>
          </div>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Total Finances
              </div>
              <div className={'col text-end text-white'}>
                {dashboaradData?.total_finances}
              </div>
            </div>
          </div>
        </div>
        <div className={'col'}>
          <h3>Finance Details</h3>
          <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Invested Amount
              </div>
              <div className={'col text-end text-white'}>
                ₹{dashboaradData?.financer_invested_amount}
              </div>
            </div>
          </div>
          <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Returnable Amount
              </div>
              <div className={'col text-end text-white'}>
                ₹{dashboaradData?.financer_returnable_amount}
              </div>
            </div>
          </div>
          <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Recovered Amount
              </div>
              <div className={'col text-end text-white'}>
                ₹{dashboaradData?.financer_recovered_amount}
              </div>
            </div>
          </div>
          <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Balance Amount
              </div>
              <div className={'col text-end text-white'}>
                ₹{dashboaradData?.financer_balance_amount}
              </div>
            </div>
          </div>
          <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Profit from Finance
              </div>
              <div className={'col text-end text-white'}>
                ₹{dashboaradData?.financcer_profit}
              </div>
            </div>
          </div>
          <Link to={'/reports/sponsorship-profit'}>
          <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Profit from Sponsorship
              </div>
              <div className={'col text-end text-white'}>
                ₹{dashboaradData?.sponsor_profit}
              </div>
            </div>
          </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
